import createMarkup from '../../../../../../js/utils/formatters/html-to-text-formatter';

const createConfig = (acf, style) => (
  {
    general: {
      wrapperPosition: 'relative',
      hero: {
        backgroundColor: '#570014',
        foregroundImage: '',
      },
    },
    columns: [
      {
        components: [
          {
            component: 'Text',
            acf: {},
            props: {
              fontWeight: style.textBlockDesktopFontWeight,
              fontSizeTitle: style.textBlockDesktopFontSizeTitle,
              dangerouslySetInnerHTML: createMarkup(`<h1>${acf.desktopHero.title}</h1>`),
            },
          },
          {
            component: 'Text',
            acf: {},
            props: {
              fontWeight: style.textBlockDesktopFontWeight,
              fontSizeTitle: style.textBlockDesktopFontSizeTitle,
              dangerouslySetInnerHTML: createMarkup(acf.pitchText),
            },
          }],
      },
      {
        components: [
          {
            component: 'MobileForeground',
            props: {
              src: '',
              showComponent: 'return evaluationValues.mobile',
            },
          },
          {
            component: 'FormBlock',
            acf: { ...acf.form },
            props: {
              color: style.formBlockColor,
              aboveFold: true,
            },
          },
        ],
      },
    ],
  }
);

export default createConfig;
