export default {
  h3FontWeight: 'var(--has-no-font-weight)',
  fontWeight: 'var(--has-no-font-weight)',
  fontWeightMobile: 'var(--has-no-font-weight)',
  bulletColor: 'var(--black)',
  tableDisplay: 'block',
  letterSpacing: 'var(--has-no-letter-spacing)',
  tableRowColorOne: '#eee',
  tableRowColorTwo: '#e2e2e2',
  tableRowWeight: 'normal',
  tablePadding: '5px',
  h3FontSize: '1.4rem',
  fontSize: '1.5rem',
  textColor: 'var(--sambla-black)',
  borderRadius: '1.75rem',
  borderWidth: '2px',
  borderColor: 'var(--black)',
  margin: '1rem auto',
  ctaTextColor: 'var(--dark-teal)',
  ctaButtonPadding: '.8em 5em',
  buttonBackgroundColor: 'var(--transparent)',
};
